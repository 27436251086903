<script setup>
import Languages from '../main/header/languages.vue';
import Account from '../main/header/account.vue';
import MenuHeader from './header/menu-header.vue';
import SocialMedia from '../main/footer/social-media.vue';
import HelpfulLinks from '../main/footer/helpful-links.vue';
import CountriesMenus from '../main/footer/countries-menus.vue';
import ContactUs from '../main/footer/contact-us.vue'


const appId = useRuntimeConfig().public.appId;

</script>

<template>

    <header>
        <div class="h-7 bg-zinc-300 flex items-center">
            <div class="container mx-auto justify-center flex max-w-7xl px-4 sm:px-6 lg:px-8">
                <div class="flex items-center ms-auto">
                    <div class="flex items-center gap-2 me-4 text-center text-zinc-800 text-xs">
                        <div class="">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                class="w-6 h-6">
                                <path
                                    d="M21.721 12.752a9.711 9.711 0 0 0-.945-5.003 12.754 12.754 0 0 1-4.339 2.708 18.991 18.991 0 0 1-.214 4.772 17.165 17.165 0 0 0 5.498-2.477ZM14.634 15.55a17.324 17.324 0 0 0 .332-4.647c-.952.227-1.945.347-2.966.347-1.021 0-2.014-.12-2.966-.347a17.515 17.515 0 0 0 .332 4.647 17.385 17.385 0 0 0 5.268 0ZM9.772 17.119a18.963 18.963 0 0 0 4.456 0A17.182 17.182 0 0 1 12 21.724a17.18 17.18 0 0 1-2.228-4.605ZM7.777 15.23a18.87 18.87 0 0 1-.214-4.774 12.753 12.753 0 0 1-4.34-2.708 9.711 9.711 0 0 0-.944 5.004 17.165 17.165 0 0 0 5.498 2.477ZM21.356 14.752a9.765 9.765 0 0 1-7.478 6.817 18.64 18.64 0 0 0 1.988-4.718 18.627 18.627 0 0 0 5.49-2.098ZM2.644 14.752c1.682.971 3.53 1.688 5.49 2.099a18.64 18.64 0 0 0 1.988 4.718 9.765 9.765 0 0 1-7.478-6.816ZM13.878 2.43a9.755 9.755 0 0 1 6.116 3.986 11.267 11.267 0 0 1-3.746 2.504 18.63 18.63 0 0 0-2.37-6.49ZM12 2.276a17.152 17.152 0 0 1 2.805 7.121c-.897.23-1.837.353-2.805.353-.968 0-1.908-.122-2.805-.353A17.151 17.151 0 0 1 12 2.276ZM10.122 2.43a18.629 18.629 0 0 0-2.37 6.49 11.266 11.266 0 0 1-3.746-2.504 9.754 9.754 0 0 1 6.116-3.985Z" />
                            </svg>
                        </div>
                        <languages></languages>
                    </div>
                </div>
            </div>
        </div>
        <MenuHeader></MenuHeader>   
    </header>

    <slot></slot>

    <footer class="bg-slate-900 py-10">
        <div class="container mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div class="flex justify-between border-b border-slate-700 pb-4 lg:flex-row md:flex-col sm:flex-col">
                <div class="flex flex-col">
                    <img :src="`/images/${appId}/logo.webp`" alt="" class="h-6 self-start mb-3   ">
                    <p class="text-white font-normal">{{ $t('client.footer_description') }}</p>
                </div>
                <social-media></social-media>
            </div>
            <div class="grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-4 my-5 border-b border-slate-700 pb-4">
                <helpful-links></helpful-links>
                <countries-menus></countries-menus>
                <contact-us></contact-us>
            </div>
            <div class="flex justify-between items-center lg:flex-row md:flex-col sm:flex-col">
                <div class="text-stone-300">&copy; {{ $t("client.copyright", { year: new Date().getFullYear() }) }}</div>
                <ul class="flex gap-6">
                    <li class="list-none">
                        <NuxtLink :to="localePath({ name: 'sitemap.index' })" class="text-stone-300 mb-3">{{ $t('client.sitemap') }}</NuxtLink>
                    </li>
                    <li class="list-none">
                        <NuxtLink :to="localePath({ name: 'contact_us' })" class="text-stone-300 mb-3">{{ $t('client.contact_us') }}</NuxtLink>
                    </li>
                </ul>
            </div>
        </div>
    </footer>

</template>

<style>
@import url('~/assets/scss/desktop/index.scss');
</style>
